import { classnames } from '@tailwindcss-classnames';

export const sectionWrapper = classnames('py-6', 'md:py-12', 'xl:py-16');

// Top Spacing
export const defaultTop = classnames('pt-6', 'md:pt-12', 'xl:pt-16');
export const smallTop = classnames('pt-4', 'md:pt-6', 'xl:pt-8');
export const noneTop = classnames('pt-0', 'md:pt-0', 'xl:pt-0');

// Bottom Spacing
export const defaultBottom = classnames('pb-6', 'md:pb-12', 'xl:pb-16');
export const smallBottom = classnames('pb-4', 'md:pb-6', 'xl:pb-8');
export const noneBottom = classnames('pb-0', 'md:pb-0', 'xl:pb-0');

export default sectionWrapper;
