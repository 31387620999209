import { FC, memo } from 'react';
import type { DatoProductImage } from '@framework/api/types';
import * as styles from './styles';
import Eyebrow from './eyebrow';

type Props = {
  eyebrow?: string;
  heading: string;
  subheading?: string;
  eyebrowLogo?: DatoProductImage;
  lang?: string;
  textPosition?: string;
  darkenSubheading?: boolean;
  headingSize?: 'small' | 'large';
};

const SectionHeading: FC<Props> = ({
  heading,
  subheading,
  eyebrowLogo,
  eyebrow,
  lang,
  textPosition,
  darkenSubheading = false,
  headingSize = 'large',
}) => {
  const sanitizedTextPosition = textPosition?.toLowerCase() || 'center';

  return heading || subheading ? (
    <div
      className={styles.wrapper(sanitizedTextPosition)}
      data-test-id="section-heading-block"
    >
      {eyebrowLogo && (
        <Eyebrow textPosition={sanitizedTextPosition} image={eyebrowLogo} />
      )}
      {eyebrow && <p>{eyebrow}</p>}
      <h2
        lang={lang}
        className={styles.heading(sanitizedTextPosition, headingSize)}
        data-test-id="section-heading"
      >
        {heading}
      </h2>
      {subheading && (
        <p
          className={styles.subheading(sanitizedTextPosition, darkenSubheading)}
          data-test-id="section-subheading"
        >
          {subheading}
        </p>
      )}
    </div>
  ) : null;
};

export default memo(SectionHeading);
