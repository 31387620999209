import { classnames } from '@tailwindcss-classnames';

// Editorial Card Wrapper
const pushToBottom = classnames('flex', 'flex-col', 'items-end', 'justify-end');
const defaultCard = classnames('h-full', 'md:h-auto');
const alwaysCard = classnames('h-full');

export const cardWrapper = (toBottom: boolean, isAlwaysTall: boolean): string =>
  classnames('relative', 'overflow-hidden', {
    [pushToBottom]: toBottom,
    [defaultCard]: !isAlwaysTall,
    [alwaysCard]: isAlwaysTall,
  });

const doubleWideStyles = classnames('col-span-2', 'lg:col-span-1');
export const relativeContainer = (useDoubleWide: boolean): string =>
  classnames('relative', {
    [doubleWideStyles]: useDoubleWide,
  });

// Image
const doubleWideImgStyles = classnames('ml-auto', 'lg:mx-auto');

export const imageStyle = (useDoubleWide: boolean): string =>
  classnames('max-w-md', 'md:w-full', {
    [doubleWideImgStyles]: useDoubleWide,
  });
export const gradient = classnames('absolute', 'top-0', 'w-full', 'h-full');

// Content
const verticalTop = classnames('top-0');
const verticalMiddle = classnames('top-1/2', '-translate-y-1/2');
const verticalBottom = classnames('bottom-0');
const center = classnames('text-center', 'mx-auto');
const horizontalCenter = classnames(center);
const lightCopy = classnames('text-white');

export const copyPosWrapper = classnames(
  'overflow-hidden',
  'absolute',
  'w-full'
);

export const copyPosition = {
  Top: verticalTop,
  Middle: verticalMiddle,
  Bottom: verticalBottom,
};

export const copyContainer = (isCenter: boolean, isInverted: boolean): string =>
  classnames('p-4', 'md:p-6', '2xl:p-8', {
    [lightCopy]: isInverted,
    [horizontalCenter]: isCenter,
  });

export const eyebrowWrapper = (isCenter: boolean): string =>
  classnames('w-3/4', 'mb-3', { [center]: isCenter });

const baseHeading = classnames('text-lg', 'md:text-xl', '2xl:text-3xl');
const baseSubheading = classnames('text-sm', 'md:text-base');
const leadHeading = classnames('text-2xl', 'md:text-3xl', '2xl:text-4xl');
const leadSubheading = classnames('text-base', 'md:text-lg');
export const heading = (useLeadText: boolean): string =>
  classnames('mb-2', 'font-bold', {
    [leadHeading]: useLeadText,
    [baseHeading]: !useLeadText,
  });
export const subheading = (useLeadText: boolean): string =>
  classnames('mb-3', 'lg:mb-4', {
    [leadSubheading]: useLeadText,
    [baseSubheading]: !useLeadText,
  });

export const buttonWrapper = (isCenter: boolean): string =>
  classnames('w-full', { [center]: isCenter });
export const buttonStyles = (isCenter: boolean): string =>
  classnames('md:max-w-min', 'text-sm', 'md:text-base', { [center]: isCenter });
