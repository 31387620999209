/* eslint-disable no-nested-ternary */
import { CSSProperties, memo, useMemo, FC, ReactNode } from 'react';
import cx from 'clsx';
import * as styles from './styles';

// TODO - handle this any
type Props = {
  children?: ReactNode;
  className?: string;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
  style?: CSSProperties;
  noSpacing?: boolean;
  variableSpacing?: string;
};

// TODO: variableSpacing is a stop gap to a real spacing solution
// based on how this is used by content
// discussion is required @dan @donny
const SectionWrapper: FC<Props> = ({
  children,
  className,
  id,
  ref,
  style,
  noSpacing = false,
  variableSpacing,
  ...rest
}) => {
  const variableSpacingStrings = variableSpacing
    ? variableSpacing?.split('/')
    : [];

  const spacingValuesTop = {
    Default: styles.defaultTop,
    Small: styles.smallTop,
    None: styles.noneTop,
  };

  const spacingValuesBottom = {
    Default: styles.defaultBottom,
    Small: styles.smallBottom,
    None: styles.noneBottom,
  };

  const composedClassName = useMemo(
    () =>
      !noSpacing
        ? variableSpacingStrings?.length === 2
          ? cx(
              spacingValuesTop[variableSpacingStrings[0]],
              spacingValuesBottom[variableSpacingStrings[1]],
              className
            )
          : cx(styles.sectionWrapper, className)
        : className
        ? cx(className)
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [className, noSpacing, variableSpacingStrings]
  );

  return (
    <section
      ref={ref}
      className={composedClassName}
      id={id}
      {...rest}
      style={style}
    >
      {children}
    </section>
  );
};

export default memo(SectionWrapper);
