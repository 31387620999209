import { classnames } from '@tailwindcss-classnames';

const centerPos = classnames('text-center', 'mx-auto');

export const wrapper = (textPos: string): string =>
  classnames(
    'space-y-2',
    'mb-6',
    'md:mb-8',
    'lg:mb-10',
    'w-11/12',
    'md:max-w-screen-sm',
    'lg:max-w-screen-lg',
    'xl:max-w-screen-xl',
    {
      [centerPos]: textPos === 'center',
    }
  );

export const heading = (textPos: string, headingSize: string): string =>
  classnames({
    h3: headingSize === 'large', // No brackets needed, just a boolean expression
    h5: headingSize === 'small',
    'hyphen-auto': true,
    [centerPos]: textPos === 'center',
  });

const darkColor = classnames('text-nzxt-light-grey-700');
const defaultColor = classnames('text-nzxt-light-grey-600');
export const subheading = (
  textPos: string,
  darkenSubheading: boolean
): string =>
  classnames('max-w-ch-50', {
    [centerPos]: textPos === 'center',
    [darkColor]: darkenSubheading,
    [defaultColor]: !darkenSubheading,
  });
export const image = (textPos: string): string =>
  classnames('h-5', 'md:h-6', 'w-auto', 'block', 'mb-3', 'md:mb-4', {
    [centerPos]: textPos === 'center',
  });
