/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { memo, FC, ReactNode } from 'react';
import Link from 'next/link';

import * as styles from './styles';

type Props = {
  children: ReactNode;
  link: string;
  eventHandle: () => void;
  useDoubleWide?: boolean;
};

const EditorialCardWrapper: FC<Props> = ({
  children,
  link,
  eventHandle,
  useDoubleWide = false,
}) =>
  link ? (
    <Link
      href={link}
      passHref
      onClick={() => eventHandle()}
      onKeyDown={() => eventHandle()}
      role="link"
      tabIndex={0}
      className={styles.relativeContainer(useDoubleWide)}
      aria-label={`Visit ${process.env.NEXT_PUBLIC_BASE_URL}${link}`}
    >
      {children}
    </Link>
  ) : (
    <div className={styles.relativeContainer(useDoubleWide)}>{children}</div>
  );

export default memo(EditorialCardWrapper);
