import { FC, memo } from 'react';
import type { DatoProductImage } from '@framework/api/types';
import * as styles from './styles';

type Props = {
  image?: DatoProductImage;
  textPosition?: string;
};

const Eyebrow: FC<Props> = ({ image, textPosition }) => {
  const { nonResponsiveUrl, alt, height, width } = image;
  return (
    <img
      className={styles.image(textPosition)}
      src={image && nonResponsiveUrl}
      alt={image && alt}
      height={image && height}
      width={image && width}
      loading="lazy"
      decoding="async"
      data-test-id="section-eyebrow"
    />
  );
};

export default memo(Eyebrow);
