/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import GTM from '@utils/gtm';
import { useTranslation as t } from '@utils/hooks';
import {
  CardWrapper,
  CardExtendedDetails,
  CardContentWrapper,
  UpperCard,
} from '@components/CardComponents';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import ButtonLink from '@components/ButtonLink';
import type { DatoProductImage } from '@framework/api/types';

import * as styles from './styles';

type Props = {
  name: string;
  slug?: {
    _modelApiKey: string;
    pageType?: string;
    slug: string;
    parentCategory?: {
      slug: string;
    };
  };
  extendedHeading?: string;
  extendedDetails?: string[];
  ctaTitle?: string;
  shortDescription?: string;
  collectionImage?: DatoProductImage;
  isSmall?: boolean;
  isExtendedCard?: boolean;
  eventType?: string;
};

const DummyProductCard: FC<Props> = ({
  name,
  slug,
  shortDescription,
  collectionImage,
  isExtendedCard,
  extendedHeading,
  extendedDetails,
  ctaTitle,
  isSmall,
  eventType,
}) => {
  const InternalLink = slug
    ? getCmsLinkUrl({
        contentType: slug._modelApiKey,
        pageType: slug.pageType,
        slug: slug.slug,
        parentSlug: slug?.parentCategory?.slug,
      })
    : null;

  const SHOP_LABEL = t('label_shop');

  const handleEvent = (): void => {
    if (eventType) {
      GTM.dataLayer({
        dataLayer: {
          event: eventType,
          interactionType: 'customCard',
        },
      });
    }
  };

  return (
    <CardWrapper>
      <UpperCard
        name={name}
        slug={InternalLink}
        shortDescription={shortDescription}
        collectionImage={collectionImage}
        isExtendedCard={isExtendedCard}
        isSmall={isSmall}
        eventType={eventType}
        isCustomCard
      />

      <CardContentWrapper data-test-id="dummy-product-card-item">
        {isExtendedCard && (
          <CardExtendedDetails
            extendedHeading={extendedHeading}
            extendedDetails={extendedDetails}
          />
        )}
        {isExtendedCard && (
          <div
            className={styles.fullWidthHeight}
            style={{ flexBasis: 'max-content' }}
          >
            <div className={styles.extendedDetailsWrapper(true)}>
              <CardExtendedDetails
                extendedHeading={ctaTitle}
                isFauxPrice
                isSmall={isSmall}
              />
            </div>
            <div className={styles.extendedDetailsWrapper(false)}>
              <ButtonLink
                asButton
                buttonStyle="primary"
                href={InternalLink}
                className={styles.buttonLabel(isSmall)}
                customMargin
                internalLink
                onClick={() => handleEvent()}
              >
                {SHOP_LABEL}
              </ButtonLink>
            </div>
          </div>
        )}
      </CardContentWrapper>
    </CardWrapper>
  );
};

export default memo(DummyProductCard);
