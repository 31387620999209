import { classnames } from '@tailwindcss-classnames';

export const buttonWrapper = classnames(
  'flex',
  'flex-col',
  'md:flex-row',
  'lg:flex-col',
  'xl:flex-row',
  'justify-center',
  'items-center',
  'mt-4',
  'w-full',
  'space-x-2'
);

export const descGrid = classnames(
  'grid',
  'gap-0',
  'grid-cols-2',
  'items-center',
  'pt-2'
);

const borderTop = classnames('border-t');
export const fullWidthHeight = classnames('w-full', 'h-full');

export const extendedDetailsWrapper = (withBorder: boolean): string =>
  classnames('grid', 'items-center', 'mt-2', 'pt-4', {
    [borderTop]: withBorder,
  });

export const textWrapper = classnames('absolute', 'bottom-0', 'left-0');
export const buildItem = classnames('absolute', 'bottom-0', 'right-0');

export const cardWrapper = classnames(
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'h-full',
  'max-w-md'
);

export const image = classnames('z-0', 'max-w-lg');
export const imageWrapper = classnames(
  'relative',
  'w-full',
  'p-4',
  'xl:p-10',
  'bg-nzxt-light-grey-50'
);

export const heading = classnames(
  'font-bold',
  'text-lg',
  'text-nzxt-light-grey-800'
);
export const extendedButton = classnames('mb-4', 'w-full');
export const copy = classnames('text-nzxt-light-grey-800');
export const options = classnames(
  'mt-2',
  'text-sm',
  'text-nzxt-light-grey-500'
);

export const quickBuyPlacement = classnames(
  'top-10',
  'right-10',
  'absolute',
  'pl-4',
  'pb-4',
  'md:pl-2',
  'md:pb-4'
);
export const colorSelectItem = classnames('flex', 'ml-auto', 'items-start');
export const lightGreyCopy = classnames(
  'ml-auto',
  'flex',
  'text-xs',
  'my-auto',
  'text-nzxt-light-grey-500',
  'underline',
  'mr-2'
);
export const shortDescription = classnames(
  'flex',
  'text-xs',
  'text-nzxt-light-grey-500',
  'col-span-2',
  'md:w-3/4'
);
export const descriptionColor = classnames('text-nzxt-light-grey-500');

export const optionsGrid = classnames('space-y-2', 'flex');
export const copyGrid = classnames(
  optionsGrid,
  'items-end',
  'text-nzxt-light-grey-800'
);
export const quickBuyButton = classnames(
  'bg-nzxt-volt-400',
  'border',
  'rounded-full',
  'p-2.5',
  'hover:animate-scaleUp'
);
export const shoppingCart = classnames(
  'text-white',
  'fill-current',
  'h-5',
  'w-5'
);

const priceDiscounted = classnames('line-through', 'text-nzxt-light-grey-500');
export const priceContainer = classnames('flex', 'space-x-2');
export const discountedPricedText = classnames('text-nzxt-light-grey-800');
export const isOnSale = (salePrice: boolean): string =>
  classnames({ [priceDiscounted]: salePrice });

const smallButtonText = classnames('text-xs', 'md:text-base');
export const buttonLabel = (isSmall: boolean): string =>
  classnames({ [smallButtonText]: isSmall });
