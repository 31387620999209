/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { memo, FC } from 'react';
import cn from 'clsx';
import GTM from '@utils/gtm';
import NextImage from 'next/legacy/image';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import { useMedia } from '@utils/hooks';
import Image from '@components/Image';
import type { DatoProductImage } from '@framework/api/types';
import ButtonLink from '@components/ButtonLink';
import EditorialCardWrapper from './EditorialCardWrapper';

import * as styles from './styles';

type Props = {
  internalLink?: {
    _modelApiKey: string;
    pageType?: string;
    slug: string;
    parentCategory?: {
      slug: string;
    };
  };
  isInverted: boolean;
  contentHorizontalAlignment: string;
  contentVerticalAlignment: string;
  eyebrowLogo: DatoProductImage;
  heading: string;
  subheading: string;
  backgroundImage: DatoProductImage;
  backgroundImageAlignment: string;
  backgroundColor: string;
  gradientColor?: {
    hex?: string;
    red?: string;
    green?: string;
    blue?: string;
    alpha?: string;
  };
  eventType?: string;
  buttonLabel?: string;
  alwaysTall?: boolean;
  useDoubleWide?: boolean;
  useLeadText?: boolean;
};

const EditorialCard: FC<Props> = ({
  isInverted = false,
  contentHorizontalAlignment,
  contentVerticalAlignment,
  eyebrowLogo,
  heading,
  subheading,
  buttonLabel,
  internalLink,
  backgroundImage,
  gradientColor,
  eventType,
  useDoubleWide = false,
  alwaysTall = false,
  useLeadText = false,
}) => {
  const link = internalLink?.slug
    ? getCmsLinkUrl({
        contentType: internalLink?._modelApiKey,
        pageType: internalLink?.pageType,
        slug: internalLink?.slug,
        parentSlug: internalLink?.parentCategory?.slug,
      })
    : null;

  const handleEvent = (): void => {
    if (eventType) {
      GTM.dataLayer({
        dataLayer: {
          event: eventType,
        },
      });
    }
  };
  const isSm = useMedia('sm');

  const isTop = contentVerticalAlignment?.toLowerCase() === 'top';
  const isCenter = contentHorizontalAlignment?.toLowerCase() === 'center';
  const hasEyebrow = Boolean(
    eyebrowLogo?.nonResponsiveUrl || eyebrowLogo?.responsiveImage
  );
  const hasGradient = Boolean(gradientColor?.hex);
  const defaultGradientTop = !isInverted
    ? 'linear-gradient(rgba(245, 245, 248, 1), rgba(245, 245, 248, 0))'
    : 'linear-gradient(rgba(28, 29, 30, 1), rgba(28, 29, 30, 0))';
  const defaultGradientBottom = !isInverted
    ? 'linear-gradient(rgba(245, 245, 248,0), rgba(245, 245, 248, 1))'
    : 'linear-gradient(rgba(28, 29, 30, 0), rgba(28, 29, 30, 1))';
  const defaultBackgroundColor = !isInverted
    ? 'rgba(245, 245, 248, 1)'
    : 'rgba(28, 29, 30, 1)';

  const gradientRGB = `${gradientColor?.red},${gradientColor?.green},${gradientColor?.blue}`;
  const backgroundTop = hasGradient
    ? `linear-gradient(rgba(${gradientRGB}, 1), rgba(${gradientRGB}, 0))`
    : defaultGradientTop;
  const backgroundBottom = hasGradient
    ? `linear-gradient(rgba(${gradientRGB}, 0), rgba(${gradientRGB}, 1))`
    : defaultGradientBottom;

  const backgroundGradient = isTop ? backgroundTop : backgroundBottom;
  const backgroundValue = hasGradient
    ? gradientColor?.hex
    : defaultBackgroundColor;

  const backgroundColor = {
    backgroundColor: backgroundValue,
  };
  const background = {
    background: backgroundGradient,
  };

  return link || subheading || heading ? (
    <>
      <EditorialCardWrapper
        link={link}
        eventHandle={handleEvent}
        useDoubleWide={
          process.env.NEXT_PUBLIC_FEATURE_WIDE_EDITORIAL_CARD === 'true' &&
          useDoubleWide
        }
      >
        <div
          className={styles.cardWrapper(
            (isTop && isSm) || (isTop && alwaysTall),
            alwaysTall
          )}
          style={backgroundColor}
        >
          <div className={styles.relativeContainer(false)}>
            {backgroundImage?.responsiveImage && (
              <Image
                className={styles.imageStyle(
                  process.env.NEXT_PUBLIC_FEATURE_WIDE_EDITORIAL_CARD ===
                    'true' && useDoubleWide
                )}
                data={backgroundImage?.responsiveImage}
                width={backgroundImage?.width}
                height={backgroundImage?.height}
              />
            )}
            {isSm && <div style={background} className={styles.gradient} />}
          </div>

          <div
            className={cn(
              styles.copyPosWrapper,
              styles.copyPosition[contentVerticalAlignment]
            )}
            style={background}
          >
            <div className={styles.copyContainer(isCenter, isInverted)}>
              {hasEyebrow && (
                <>
                  {eyebrowLogo?.responsiveImage ? (
                    <div>
                      <Image
                        data={eyebrowLogo?.responsiveImage}
                        width={eyebrowLogo?.responsiveImage?.width}
                        height={eyebrowLogo?.responsiveImage?.height}
                        style={{ maxWidth: '200px' }}
                        className={styles.eyebrowWrapper(isCenter)}
                      />
                    </div>
                  ) : (
                    <div style={{ maxWidth: '200px' }}>
                      <NextImage
                        src={eyebrowLogo?.nonResponsiveUrl}
                        width={eyebrowLogo?.width}
                        height={eyebrowLogo?.height}
                        className={styles.eyebrowWrapper(isCenter)}
                      />
                    </div>
                  )}
                </>
              )}
              {heading && !hasEyebrow && (
                <p className={styles.heading(useLeadText)}>{heading}</p>
              )}
              {subheading && (
                <p className={styles.subheading(useLeadText)}>{subheading}</p>
              )}
              {buttonLabel && link && (
                <div className={styles.buttonWrapper(isCenter)}>
                  <ButtonLink
                    href={link}
                    asButton={!isSm}
                    fauxButton
                    buttonStyle="primary"
                    dark={isInverted}
                    customMargin
                    className={styles.buttonStyles(isCenter)}
                  >
                    {buttonLabel}
                  </ButtonLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </EditorialCardWrapper>
    </>
  ) : null;
};

export default memo(EditorialCard);
